<template>
  <div class="contact-us">
    <p>
      <strong>{{ $locale["contact_us"] }}</strong>
    </p>
    <ul list-padding-bottom>
      <li>
        <div>
          <small block>Tel:</small>
          <a :href="`tel:+${$locale['support']['phone_number']}`">{{ "+" + $locale["support"]["phone_number"] }}</a>
        </div>
      </li>
      <li>
        <div>
          <small block>Email:</small>
          <a :href="`mailto:${$locale['support']['email']}`">{{ $locale["support"]["email"] }}</a>
        </div>
      </li>
    </ul>
    <Spacer num="1" />
    <p>
      <strong>{{ $locale["office_hours"] }}</strong>
    </p>
    <Schedule />
  </div>
</template>

<script>
export default {
  components: {
    Schedule: () => import("./Schedule.vue"),
  },
};
</script>
